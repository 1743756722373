import Cookies from 'js-cookie'

const TokenKey = 'Luck-platform-Token' //p
const TokenKey1 = 'Mall4cloud-multishop-Token'//s

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token )
  // {
  //   domain: '.xbee.emergen.cn:13580/'
  //   // domain: '10.10.12.9:9528'
  // sameSite : 'lax',
  //   domain: 'localhost'
  // }
}

export function setToken1(token) {
  return Cookies.set(TokenKey1, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
