<template>
  <div class="home" id="home">
    <img :src="topRightLogo" alt="" style="width: 167px;height: 44px; position: relative;top:40px;left: 50px;">
    <div class="login">
      <div class="logo">
        <img :src="siteLogo" alt="" style="width:168px;height:114px;">
      </div>
      <div class="text">
        <span class="text_one">{{ title1 }}</span>
        <span class="text_two">{{ title2 }}</span>
      </div>
      <div class="bottom">
        <a-form-model :rules="rules" :model="formInline" @submit="handleSubmit" @submit.native.prevent>
          <a-form-model-item prop="user">
            <a-input v-model="formInline.user" placeholder="请输入账号">
              <div slot="prefix" type="user" style="display: flex;">
                <img src="../assets/账号2.png" alt="" style="width: 18px;height: 22px;">
                <div style="width: 1px;height: 23px;background-color: rgba(0,0,0,0.15); margin-left: 15px;"></div>
              </div>
            </a-input>
          </a-form-model-item>
          <a-form-model-item prop="password">
            <a-input-password v-model="formInline.password" type="password" placeholder="请输入密码">
              <div slot="prefix" type="user" style="display: flex;">
                <img src="../assets/密码2.png" alt="" style="width: 18px;height: 22px;">
                <div style="width: 1px;height: 23px;background-color: rgba(0,0,0,0.15); margin-left: 15px;"></div>
              </div>
            </a-input-password>
          </a-form-model-item>
          <a-form-model-item>
            <a-checkbox @change="onChange" :checked="checked">
              <span style="color: #ffff;">记住密码</span>
            </a-checkbox>
            <a-button type="primary" html-type="submit"
              style="width: 100%;width: 360px;height: 50px;background: #B28957;border-radius: 4px;margin-top: 20px;border: 0;">
              登录
            </a-button>
          </a-form-model-item>
        </a-form-model>
        <Verify v-if="initVerify" ref="verify" :captcha-type="'blockPuzzle'"
          :img-size="{ width: '400px', height: '200px' }" @success="login" />
      </div>
    </div>
    <p style="color: #ffffff;position: fixed;bottom: 20px;text-align: center;width: 100%;">{{ copyRight }} &nbsp;|&nbsp; <a href="https://beian.miit.gov.cn/">{{ icpNo }}</a></p>
  </div>
</template>

<script>
import Verify from '../components/Verifition/Verify'
import { userLogin, sysType, getConfig ,homeStyle} from '../api/home'
import { encrypt } from '../components/Verifition/utils/crypto'
import { setToken, setToken1 } from '../utils/auth.js'

export default {
  name: 'HomeView',
  components: {
    Verify
  },
  data() {
    return {
      icpNo:'',
      copyRight:'',
      topRightLogo:'',
      siteLogo:'',
      title1:'',
      title2:'',
      formInline: {
        user: '',
        password: '',
      },
      initVerify: false,
      verify: null,
      type: null,
      url: '',
      rules: {
        user: [
          { required: true, message: '账号不能为空', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
        ],
      },
      checked: false,
    }
  },
  created() {
    var vm = this;
    // 在页面加载时从cookie获取登录信息
    let userName = vm.getCookie("userName");
    let userPwd = vm.getCookie("userPwd");

    // 如果存在赋值给表单，并且将记住密码勾选
    if (userName) {
      vm.formInline.user = userName;
      vm.formInline.password = userPwd;
      vm.checked = true;
    }
    this.homeStyle()
  },
  mounted() {
    
  },
  methods: {
    homeStyle(){
      homeStyle().then(res=>{
        sessionStorage.setItem('style',res)
        const style = JSON.parse(res)
        
        this.topRightLogo = style.developLogo
        this.siteLogo = style.siteLogo
        this.title1 = style.title1
        this.title2 = style.title2
        this.copyRight = style.copyRight
        this.icpNo = style.icpNo
        console.log(style)
        if(style.backgroundImg){
          const bag = document.getElementById('home')
          bag.style.background=`url(${style.backgroundImg}) no-repeat`
          bag.style.backgroundSize = `100% 100%`;
          console.log(bag.style)
        }
      })
    },
    handleSubmit(e) {
      if (this.formInline.user == '' || this.formInline.password == '') {
        console.dir('请填写完整')
      } else {
        console.log(this.formInline);
        this.initVerify = true
        this.$nextTick(() => {
          this.$refs.verify.show()
        })
      }

    },
    //记住密码
    onChange(e) {
      console.log(e.target.checked);
      this.checked = e.target.checked
    },
    login(verifyResult) {
      console.log(verifyResult.captchaVerification)
      userLogin({
        userName: this.formInline.user,
        passWord: encrypt(this.formInline.password),
        sysType: 2,
        captchaVerification: verifyResult.captchaVerification
      }).then(res => {
        console.log(res)
        if (res) {
          let obj = {
            'token': res.accessToken,
            'expiresIn': res.expiresIn
          }
          setToken(res.accessToken)
          setToken1(res.accessToken)
          sysType().then(res => {
            console.log(res)
            this.type = res
            localStorage.setItem('sysType', JSON.stringify(res))
          })
          const test = res.accessToken
          this.setUserInfo()
          getConfig().then(res => {
            let data = JSON.parse(res)
            data.forEach(element => {
              console.log(element)
              if (element.sys_type == this.type) {
                this.url = element.url
                console.log(this.url + '#/login?token=' + test)
                window.location.href = this.url+'#/login?token='+test
              }
            });
          })
        }else{
          this.$message.error('用户名或密码不正确');
        }

      })
    },
    //存取用户名密码
    setUserInfo() {
      var vm = this;
      // 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
      // 如果没有勾选，储存的信息为空
      if (vm.checked) {

        vm.setCookie("userName", vm.formInline.user);
        vm.setCookie("userPwd", vm.formInline.password);
        vm.setCookie("checked", vm.checked);
      } else {
        vm.setCookie("userName", "");
        vm.setCookie("userPwd", "");
      }
    },
    // 获取cookie
    getCookie: function (key) {
      if (document.cookie.length > 0) {
        var start = document.cookie.indexOf(key + '=')
        if (start !== -1) {
          start = start + key.length + 1
          var end = document.cookie.indexOf(';', start)
          if (end === -1) end = document.cookie.length
          return unescape(document.cookie.substring(start, end))
        }
      }
      return ''
    },
    // 保存cookie
    setCookie(cName, value, expiredays) {
      var exdate = new Date()
      exdate.setDate(exdate.getDate() + expiredays)
      document.cookie = cName + '=' + decodeURIComponent(value) +
        ((expiredays == null) ? '' : ';expires=' + exdate.toGMTString())
    }
  },
}
</script>
<style  lang="scss" scoped>
.home {
  margin: 0 auto;
  padding: 0;
  height: 100vh;
  background: url('~@/assets/bg.png') no-repeat;
  background-size: 100% 100%;
  position: relative;

  .login {
    width: 470px;
    height: 632px;
    // background-color: pink;
    position: absolute;
    top: 50%;
    margin: -316px 0 0 -235px;
    left: 50%;

    .logo {
      width: 343px;
      height: 92px;
      margin: 0 auto;
      text-align: center;
    }

    .text {
      width: 100%;
      margin-top: 30px;
      text-align: center;

      .text_one {
        font-size: 26px;
        font-family: ZhenyanGB-Regular, ZhenyanGB;
        font-weight: 400;
        color: #B28957;
        line-height: 26px;
        letter-spacing: 3px;
      }

      .text_two {
        font-size: 26px;
        font-family: ZhenyanGB-Regular, ZhenyanGB;
        font-weight: 400;
        color: #ffffff;
        line-height: 26px;
        letter-spacing: 3px;
      }
    }

    .bottom {
      width: 100%;
      height: 409px;
      background: url('~@/assets/bgb.png') no-repeat;
      background-size: 100% 100%;
      margin-top: 65px;
      padding: 0 55px;
      padding-top: 94px;

      ::v-deep .ant-input-affix-wrapper .ant-input {
        height: 50px;
      }

      ::v-deep .ant-input-affix-wrapper .ant-input:not(:first-child) {
        padding-left: 60px;
      }
    }
  }
}
::v-deep .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #B28957;
    border:0;
}
</style>
