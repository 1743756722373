import axios  from "axios";
import { getToken } from '../utils/auth'

const service = axios.create({
    baseURL:'https://gateway.woodinternet.cn/wood',
    timeout:5000
})

// //前置拦截器
service.interceptors.request.use(
    config => {
      // do something before request is sent
        let obj =JSON.parse(window.localStorage.getItem("token")) 
        console.log(obj)
        config.headers['Authorization'] = getToken()
      return config
    },
    error => {
      // do something with request error
      console.log(error) // for debug
      return Promise.reject(error)
    }
  )


//后置拦截器
service.interceptors.response.use(config=> {
    return config.data.data
})

export default service