import request from './request'

//登陆
export function userLogin(data) {
   return  request({
      url: '/mall4cloud_auth/ua/industry_login',
      method: 'post',
      data
    })
  }
//   http://localhost:8000/industry-intelligence/mp/common_user/getSysTypeInfo
  //获取sysType
  export function sysType(data) {
    return  request({
       url: '/industry-intelligence/mp/common/getSysTypeInfo',
       method: 'get',
       params:data
     })
   }

   //跳转的页面
//    http://localhost:8000/industry-intelligence/mp/common/getConfig/LOGIN_SUCCESS_REDIRECT
///industry-intelligence/mp/common/getConfig/LOGIN_SUCCESS_REDIRECT
//  /industry-intelligence/mp/common/getConfig/LOGIN_SUCCESS_REDIRECT

   export function getConfig(data) {
    return  request({
       url: '/industry-intelligence/mp/common/getConfig/LOGIN_SUCCESS_REDIRECT',
       method: 'get',
       params:data
     })
   }
//页面图片样式接口
   export function homeStyle(data) {
    return  request({
       url: '/industry_intelligence/ua/industry_register/getConfig/PORTAL_WEBSITE_CONFIG',
       method: 'get',
       params:data
     })
   }
  